@import '~normalize.css';
@import '~@blueprintjs/core/lib/css/blueprint.css';
@import '~@blueprintjs/icons/lib/css/blueprint-icons.css';
@import '~@blueprintjs/datetime/lib/css/blueprint-datetime.css';

@import './admin.scss';
@import './app.scss';

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  padding-right: none;
  padding-left: none;
}

.dynamic-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 10px;
}

@media (max-width: 1000px) {
  .dynamic-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 10px;
  }
}

@media (max-width: 600px) {
  .dynamic-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
  }
}

@media (max-width: 450px) {
  .dynamic-grid {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 10px;
  }
}

.cart-element {
  max-width: 250px;
  padding: 1rem;
}

.carousel {
  .carousel-item-image {
    min-height: 600px;
    width: 100%;
  }
}

.site-container {
  min-height: 100vh;
}

.main {
  background-color: #efeee9f5;
  padding-left: none;
  padding-right: none;
}

/************ Producst Card************/

.products {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  //background-color: #efeee9f5;
}
.product {
  border: 1px #ee00ff00 solid;
  margin: 1rem;
}
.product-img {
  width: 500px;
  height: 500px;
}
.product-info {
  padding: 1rem;
}

// .btn-primary {
//   background-color: #beb9ad;
//   color: #000000;
// }
.image-large {
  max-width: 100%;
}

.img-thumbnail {
  height: 80px;
}

.small-container {
  max-width: 600px;
}

/************nav sup************/

nav.navbar {
  padding: 18px 0;
  transition: 0.32s ease-in-out;
  padding-top: 0.6rem;
  padding-bottom: 0.6rem;
}

.navbar-nav {
  background-image: url('../assets/img/NavMickey.png');
  background-repeat: no-repeat;

  background-size: contain;
  background-position: center left;
  height: 100%;
  width: 100%;
  padding-left: 2rem;
  padding-right: 2rem;
  padding-left: 2.5rem;
}

nav.navbar .navbar-nav a.nav-link.navbar-link.active {
  opacity: 1;
  align-items: flex-end;
}

span.navbar-text {
  display: flex;
  align-items: center;
  text-align: center;
}

.colmap {
  display: flex;
  justify-content: center;
}

.card-product {
  height: 25rem;
  width: 100%;
  max-width: 15rem;
  transition: 0.3s;

  &:hover {
    box-shadow: 0 8px 8px rgba(0, 0, 0, 0.2);
  }

  .card-img-top {
    justify-content: center;
    height: 13rem;
    width: 100%;
    display: flex;
  }

  .buttom-card {
    // height: 1cm;
    //  width: 5cm;
    // border: #000000;
    // padding-bottom: 1rem;
  }

  .p-name {
    color: #000000;
    font-size: 12px;
    text-decoration: none;
  }

  .card-title {
    font-size: 20px;

    color: #323232;
    text-align: center;
    //padding-right: 9rem;
  }
}

.card-size {
  height: 12rem;
  width: 12rem;
  padding-left: 2rem;
  padding-right: 2rem;
  padding-top: 1rem;
}

/************ Carrousel sup ***********/

.carrousel-slider {
  width: 40rem;

  display: flex;
}
.item {
  height: 100%;
  width: 100%;
  display: flex;
  position: relative;
  flex: auto;
  display: flex;
  padding-right: 1rem;
  padding-left: 1rem;
}

/************ Notice secc ************/

.father-notice {
  background-image: url('../assets/img/BG-Notice.png');
  background-attachment: fixed;
  width: 100%;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
}

.notice-title-div {
  background-color: #858179;
  padding: 2rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  width: 100%;
}

.notice-title {
  color: white;
}

.card-title-notice {
  font-size: 32px;
  font-weight: 790;
  text-decoration: dashed;
  text-align: center;
}

.card-text-notice {
  font-size: 20px;
  font-weight: 400;
  text-align: center;
}

.card-body-notice {
  padding-top: 2rem;
}

.switcher {
  display: flex;
  flex-wrap: wrap;
  gap: var(--s1);

  & > * {
    flex-grow: 1;
    flex-basis: calc((var(--brp) - 100%) * 999);
    padding: 0.5rem;
  }
}

.card-body-notice2 {
  padding-left: 5rem;
  padding-right: 2rem;
  padding-top: 5rem;
  padding-bottom: 5rem;
  flex: auto;
}

.img-notice {
  // height: 7cm;
  // width: 11cm;
  // flex: auto;
}

.Img-notice {
  float: left;
  flex: auto;
  display: flex;
}

/************ social insta facebook ***********/
.title-link {
  text-decoration: none;
  font-weight: 500;
  text-decoration-color: #000000;
  color: #000000;
}

.father-socialsecc {
  // padding-left: 10rem;
  //  padding-right: 10rem;
  background-color: #f9f3f3;
  width: 100%;
}

.social-Secc {
  background-color: #efeee9f5;
  padding-top: 3rem;
  padding-bottom: 5rem;
  flex-wrap: wrap;
}

.card-group-social {
  display: flex;
  flex-direction: wrap;
  flex-wrap: wrap;
  justify-content: center;
}

.card-img-social {
  flex-direction: row;
  display: flex;
  padding-left: 1rem;
  padding-right: 1rem;
}

.social-title {
  text-align: center;
  padding-top: 4rem;
  padding-bottom: 3rem;
}

.card-Social {
  justify-content: center;
  padding-bottom: 4rem;
}

.social-instagram {
  width: 500px;
  height: 150px;
  max-width: 40vw;
}

.social-facebook {
  width: 500px;
  height: 150px;
  max-width: 40vw;
}

.card-group2 {
  display: flex;
  justify-content: center;
}

/************ sub footer footer***********/
.f-sfoot {
  background-image: url('../assets/img/bg-Sf.png');
  background-attachment: fixed;
  // padding-right: 10rem;
  // padding-left: 10rem;
  // padding-bottom: 4rem;
  // padding-top: 4rem;
  padding: 2rem;
}

.horario {
  // margin-top: 1.5rem;
  // margin-bottom: 1.5rem;
  // //float: left;
  // margin-left: 1rem;
  // margin-right: 1rem;
}

.ver-nav {
  // float: left;
  // margin-top: 1.5rem;
  // margin-bottom: 1.5rem;
  // margin-left: 2.5rem;
  // margin-right: 2.5rem;
}

.nav-link-FOOT {
  transition: 0.32s ease-in-out;
  color: #000000;
  text-decoration-line: none;
  font-weight: 680;
  font-size: 19px;
}

.inputs-foot {
  display: flex;
  flex-wrap: nowrap;

  // margin-top: 1.5rem;
  // margin-bottom: 1.5rem;
  // margin-left: 4rem;
  // margin-right: 1rem;
  // flex-direction: row;
}

.inserMail {
  background-color: yellow;
  border: none;
  padding-top: 0.4rem;
  padding-bottom: 0.4rem;
}
.buttomS {
  padding-top: 0.4rem;
  padding-bottom: 0.4rem;
  border: none;
  color: #fcf9f9;
  background-color: black;
}

.textsf {
  // float: right;
  // margin-top: 1.5rem;
  // margin-bottom: 1.5rem;
}

/************ social / footer***********/
.foot {
  background-color: #000000;
  padding-top: 0.9rem;
  padding-bottom: 0.9rem;
  padding-left: 2rem;
  padding-right: 2rem;
}

.text-foot {
  color: white;
  font-size: larger;
}

.imgicon {
  float: right;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
}

@media only screen and (max-width: 720px) {
  .card-group2 {
    flex-wrap: wrap;
  }
}
