@import "./colors.scss";

//SECTIONS

@import "./colors.scss";

//SECTIONS

.home {
  .custom-right-arrow {
    position: absolute !important;
    right: 0em;
    top: 50%;
    z-index: 1;
    display: inline-block;
    color: rgb(200, 200, 200);
    padding: 6px;
    opacity: 0.8;
    cursor: pointer;
    font-weight: lighter;
    font-size: 50px;
    transform: translate(0, -50%);
  }
  .custom-right-arrow:hover {
    opacity: 0.5;
  }
  .custom-left-arrow {
    position: absolute !important;
    left: 0em;
    top: 50%;
    z-index: 1;
    display: inline-block;
    padding: 6px;
    opacity: 0.8;
    cursor: pointer;
    font-size: 50px;
    font-weight: lighter;
    color: rgb(200, 200, 200);
    transform: translate(0, -50%);
  }
  .custom-left-arrow:hover {
    opacity: 0.5;
  }

  .react-multi-carousel-item {
    display: flex;
    justify-content: center;
  }

  .banner {
    overflow: hidden;
    height: 240px;
    background-color: rgb(239, 239, 239);
  }

  .carousel {
    z-index: 1;
    max-height: 300px;
    overflow: hidden;

    .carousel-inner {
      height: 300px;
    }

    .carousel-item {
      height: 300px;
    }
    img {
      width: 100%;
    }
  }

  .section-header {
    .text-header {
      p {
        color: $secondaryColor;
        padding: 0;
        margin: 0;
        font-weight: bold;
        font-size: 25px;
      }
    }

    .icon-header {
      i {
        color: $secondaryColor;
        font-size: 30px;
      }
    }
  }

  .banner-loading {
    width: 100%;
    max-height: 300px;
    height: 300px;
    animation-name: banner-loading;
    animation-duration: 1s;
    animation-iteration-count: infinite;
    background-color: rgb(239, 239, 239);
  }

  @keyframes banner-loading {
    0% {
      background-color: rgb(239, 239, 239);
    }
    50% {
      background-color: rgb(220, 220, 220);
    }
    100% {
      background-color: rgb(239, 239, 239);
    }
  }
}

.orders-section {
  position: relative;

  .section-header {
    .text-header {
      p {
        color: $primaryColor;
        padding: 0;
        margin: 0;
        font-weight: bold;
        font-size: 25px;
      }
    }

    .icon-header {
      i {
        color: $primaryColor;
        font-size: 30px;
      }
    }
  }

  .state-approved {
    background-color: yellowgreen;
    padding: 10px;
    border-radius: 15px;
    color: white;
    font-weight: bold;
    text-align: center;
  }

  .state-pending {
    background-color: $primaryColor;
    color: white;
    border-radius: 15px;
    padding: 10px;
    font-weight: bold;
    text-align: center;
  }

  .state-rejected {
    background-color: rgb(219, 13, 13);
    color: white;
    border-radius: 15px;
    padding: 10px;
    font-weight: bold;
    text-align: center;
  }
}

.search-result {
  position: relative;
  .info-block {
    color: $normalGrey;

    .section-header {
      .text-header {
        p {
          color: $secondaryColor;
          padding: 0;
          margin: 0;
          font-weight: bold;
          font-size: 25px;
        }
      }

      .icon-header {
        i {
          color: $secondaryColor;
          font-size: 30px;
        }
      }
    }
  }

  .drawer-category-item {
    cursor: pointer;

    .drawer-category-icon {
      i {
        color: $primaryDark;
      }
    }

    .drawer-category-text {
      p {
        padding: 0 !important;
        margin: 0 !important;
        font-size: 20px;
        color: $primaryDark;
        font-weight: bold;
      }
    }
  }
}

.login {
  background-color: $primaryColor;
  height: 100vh;
  justify-content: flex-end;
  align-items: center;
  position: relative;

  .loading-module {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;

    .loading-header {
      color: white;
      font-size: 20px;

      animation-name: beating;
      animation-duration: 1s;
      animation-iteration-count: infinite;
      animation-timing-function: ease-in-out;
    }

    @keyframes beating {
      0% {
        opacity: 1;
      }
      50% {
        opacity: 0.4;
      }
      100% {
        opacity: 1;
      }
    }
  }

  .account-header {
    .icon-header {
      i {
        color: white;
        font-size: 25px;
      }
    }

    .text-header {
      p {
        color: white;
        padding: 0;
        margin: 0;
        font-size: 25px;
      }
    }
  }

  .login-panel {
    width: 500px;
    padding: 40px;
    background-color: white;
    height: 70%;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;

    .form-group {
      label {
        color: rgb(120, 120, 120);
      }

      .form-control {
        border: none;
        background-color: rgb(239, 239, 239);
      }
    }

    .sign-up-link {
      p {
        color: rgb(120, 120, 120);
        a {
          text-decoration: underline;
          cursor: pointer;
          transition: 0.3s;

          &:hover {
            color: $primaryColor;
          }
        }
      }
    }
  }
}

.sign-up {
  background-color: $primaryColor;
  height: 100vh;
  justify-content: flex-end;
  align-items: center;
  position: relative;

  .input-group-prepend {
    span {
      border: none !important;
      background-color: $primaryColor;
      color: white;
    }
  }

  .loading-module {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;

    .loading-header {
      color: white;
      font-size: 20px;

      animation-name: beating;
      animation-duration: 1s;
      animation-iteration-count: infinite;
      animation-timing-function: ease-in-out;
    }

    @keyframes beating {
      0% {
        opacity: 1;
      }
      50% {
        opacity: 0.4;
      }
      100% {
        opacity: 1;
      }
    }
  }

  .account-header {
    .icon-header {
      i {
        color: white;
        font-size: 25px;
      }
    }

    .text-header {
      p {
        color: white;
        padding: 0;
        margin: 0;
        font-size: 25px;
      }
    }
  }

  .sign-up-panel {
    width: 500px;
    padding: 40px;
    background-color: white;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;

    .form-group {
      label {
        color: rgb(120, 120, 120);
      }

      .form-control {
        border: none;
        background-color: rgb(239, 239, 239);
      }

      .invalid-input {
        color: $errorColor;
        margin-top: 2px;
        i {
          font-size: 18px;
        }
        p {
          padding: 0;
          margin: 0;
        }
      }
    }

    .sign-up-link {
      p {
        color: rgb(120, 120, 120);
        a {
          text-decoration: underline;
          cursor: pointer;
          transition: 0.3s;

          &:hover {
            color: $primaryColor;
          }
        }
      }
    }
  }
}

.product-item {
  border-radius: 15px;
  box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.1);
  width: 240px;
  overflow: hidden;

  .product-chamber {
    padding: 10px;
    height: 180px;
    background-color: $softPrimaryColor;
    cursor: pointer;
  }

  .product-body {
    position: relative;
    background-color: white;
    padding: 20px;

    .bs-usd {
      position: absolute;
      bottom: 10px;
      right: 5px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .custom-switch {
        transform: rotateZ(90deg) !important;
      }

      .bs {
        p {
          padding: 0;
          margin-bottom: 8px;
          color: $secondaryColor;
          font-weight: 500;
        }
      }
      .custom-control-input:checked + .custom-control-label::before {
        background-color: $primaryColor;
        border-color: $primaryColor;
      }

      .usd {
        p {
          margin: 0;
          padding: 0;
          color: $secondaryColor;
          font-weight: 500;
        }
      }
    }

    .btn-edit {
      border-radius: 50%;
      width: 35px;
      height: 35px;
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
      top: 8px;
      right: 8px;
      background-color: $primaryColor;
      color: white;
      border: 1.5px solid $primaryColor;

      &:hover {
        color: $primaryColor;
        background-color: white;
      }
    }

    .product-header {
      color: $secondaryColor;
    }

    .product-quantity {
      color: $secondaryColor;
    }

    .product-price {
      color: $secondaryColor;
      font-size: 18px;
    }
  }
}

.admin {
  background-color: $primaryColor;
  height: 100%;
  min-height: 100vh;
  position: relative;

  .admin-panel {
    width: 60%;
    margin-left: auto;
    margin-right: auto;
    background-color: white;
    padding: 40px;
    height: 100vh;

    .loading-module {
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;

      .loading-header {
        color: $primaryColor;
        font-size: 20px;

        animation-name: beating;
        animation-duration: 1s;
        animation-iteration-count: infinite;
        animation-timing-function: ease-in-out;
      }

      .lds-default div {
        position: absolute;
        width: 6px;
        height: 6px;
        background: $primaryColor !important;
        border-radius: 50%;
        animation: lds-default 1.2s linear infinite;
      }

      @keyframes beating {
        0% {
          opacity: 1;
        }
        50% {
          opacity: 0.4;
        }
        100% {
          opacity: 1;
        }
      }
    }

    .account-header {
      .icon-header {
        i {
          color: $primaryColor;
          font-size: 30px;
        }
      }

      .text-header {
        p {
          color: $primaryColor;
          padding: 0;
          margin: 0;
          font-size: 25px;
        }
      }
    }

    .form-group {
      label {
        color: rgb(120, 120, 120);
      }

      .form-control {
        border: none;
        background-color: rgb(239, 239, 239);
      }

      .invalid-input {
        color: $errorColor;
        margin-top: 2px;
        i {
          font-size: 18px;
        }
        p {
          padding: 0;
          margin: 0;
        }
      }
    }
  }

  .dashboard {
    padding: 40px;
    position: relative;

    .back-frame {
      cursor: pointer;

      i {
        color: white;
      }

      p {
        padding: 0;
        margin: 0;
        color: white;
      }
    }

    .account-header {
      .icon-header {
        i {
          color: white;
          font-size: 30px;
        }
      }

      .text-header {
        h4 {
          color: white;
          padding: 0;
          margin: 0;
          font-size: 25px;
        }
      }
    }

    .dash-item {
      padding: 40px;
      background-color: white;
      border-radius: 15px;
      cursor: pointer;
      margin: 20px;
      transition: 0.3s;
      width: 200px;

      &:hover {
        box-shadow: 0 8px 8px 0 rgba(0, 0, 0, 0.1);
      }

      .dash-icon {
        i {
          color: $primaryColor;
          font-size: 35px;
        }
      }

      .dash-title {
        h6 {
          padding: 0;
          margin: 0;
          color: $primaryColor;
        }
      }
    }
  }
}

.orders {
  background-color: white;
  border-radius: 15px;
  padding: 40px;

  .form-group {
    label {
      color: rgb(120, 120, 120);
    }

    .custom-select {
      outline: none !important;
      box-shadow: none !important;
      background-color: rgb(239, 239, 239);
      border: none !important;
    }

    .form-control {
      border: none;
      background-color: rgb(239, 239, 239);
      resize: none;
    }

    .invalid-input {
      color: $errorColor;
      margin-top: 2px;
      i {
        font-size: 18px;
      }
      p {
        padding: 0;
        margin: 0;
      }
    }
  }

  h5 {
    font-weight: bold;
    color: $primaryColor;
    font-size: 25px;
  }

  .tabs {
    border-bottom: 1px solid $lightNormalGrey;
    .tab {
      padding: 10px;
      border-radius: 4px;
      border: 1px solid $lightNormalGrey;

      &.active {
        background-color: $primaryColor;

        i {
          color: white;
        }

        span {
          color: white;
        }
      }
      i {
        font-size: 25px;
      }

      span {
        font-size: 20px;
      }
    }
  }
}

.promotions {
  background-color: white;
  border-radius: 15px;
  padding: 40px;

  h5 {
    padding: 0;
    margin: 0;
    color: $primaryColor;
    font-weight: bold;
    font-size: 25px;
  }
  .promotions-panel {
    .promotion-item {
      height: 100px;
      width: 800px;
      border-radius: 15px;
      overflow: hidden;
      cursor: pointer;
      transition: 0.3s;

      &:hover {
        box-shadow: 0 8px 8px 0 rgba(0, 0, 0, 0.1);
      }

      .custom-file {
        height: 100px !important;
        border: none !important;
        background-color: transparent !important;
        cursor: pointer;

        .custom-file-input {
          height: 100%;
          border: none !important;
          background-color: transparent !important;
          cursor: pointer;
        }
        .custom-file-label {
          height: 100% !important;
          border: none !important;
          background-color: transparent !important;
          cursor: pointer;

          &:after {
            content: "";
            background-color: transparent;
            border: none !important;
            cursor: pointer;
          }
        }
      }
    }

    .promotion-item-add {
      padding: 10px;
      border-radius: 15px;
      border: 1.5px solid $primaryColor;
      background-color: $primaryColor;
      cursor: pointer;
      position: relative;
      transition: 0.3s;

      .custom-file {
        height: 100%;
        width: 100%;
        border: none !important;
        background-color: transparent !important;
        cursor: pointer;
        position: absolute;
        top: 0;
        left: 0;
        outline: none !important;
        box-shadow: none !important;

        &:active {
          outline: none !important;
          box-shadow: none !important;
        }

        &:focus {
          outline: none !important;
          box-shadow: none !important;
        }

        .custom-file-input {
          height: 100%;
          width: 100%;
          border: none !important;
          background-color: transparent !important;
          cursor: pointer;
          outline: none !important;
          box-shadow: none !important;

          &:focus {
            outline: none !important;
            box-shadow: none !important;
          }
          &:active {
            outline: none !important;
            box-shadow: none !important;
          }
        }
        .custom-file-label {
          height: 100% !important;
          border: none !important;
          background-color: transparent !important;
          cursor: pointer;
          height: 100%;
          width: 100%;
          outline: none !important;
          box-shadow: none !important;

          &:hover {
            outline: none !important;
            box-shadow: none !important;
          }

          &:active {
            outline: none !important;
            box-shadow: none !important;
          }

          &:after {
            content: "";
            background-color: transparent;
            border: none !important;
            cursor: pointer;
          }
        }
      }

      &:hover {
        background-color: transparent;
        .promotion-item-add-icon {
          i {
            color: $primaryColor;
            font-size: 30px;
          }
        }

        .promotion-item-add-text {
          p {
            margin: 0;
            padding: 0;
            color: $primaryColor;
            font-size: 20px;
          }
        }
      }

      .promotion-item-add-icon {
        i {
          color: white;
          font-size: 30px;
          transition: 0.3s;
        }
      }

      .promotion-item-add-text {
        p {
          margin: 0;
          padding: 0;
          color: white;
          font-size: 20px;
          transition: 0.3s;
        }
      }
    }
  }
}

.inventory {
  background-color: white;
  border-radius: 15px;
  padding: 40px;
  height: 100% !important;

  .product-creation {
    h5 {
      padding: 0;
      margin: 0;
      color: $primaryColor;
      font-weight: bold;
      font-size: 25px;
    }
  }

  .product-filter {
    .form-group {
      label {
        color: rgb(120, 120, 120);
      }

      .custom-select {
        outline: none !important;
        box-shadow: none !important;
        background-color: rgb(239, 239, 239);
        border: none !important;
      }

      .form-control {
        border: none;
        background-color: rgb(239, 239, 239);
        resize: none;
      }

      .invalid-input {
        color: $errorColor;
        margin-top: 2px;
        i {
          font-size: 18px;
        }
        p {
          padding: 0;
          margin: 0;
        }
      }
    }
  }

  .products-panel {
    padding: 20px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }

  .product-form {
    position: relative;

    .product-images {
      padding: 5px;

      .custom-file {
        height: 100%;
        width: 100%;
        border: none !important;
        background-color: transparent !important;
        cursor: pointer;
        position: absolute;
        top: 0;
        left: 0;
        outline: none !important;
        box-shadow: none !important;

        &:active {
          outline: none !important;
          box-shadow: none !important;
        }

        &:focus {
          outline: none !important;
          box-shadow: none !important;
        }

        .custom-file-input {
          height: 100%;
          width: 100%;
          border: none !important;
          background-color: transparent !important;
          cursor: pointer;
          outline: none !important;
          box-shadow: none !important;

          &:focus {
            outline: none !important;
            box-shadow: none !important;
          }
          &:active {
            outline: none !important;
            box-shadow: none !important;
          }
        }
        .custom-file-label {
          height: 100% !important;
          border: none !important;
          background-color: transparent !important;
          cursor: pointer;
          height: 100%;
          width: 100%;
          outline: none !important;
          box-shadow: none !important;

          &:hover {
            outline: none !important;
            box-shadow: none !important;
          }

          &:active {
            outline: none !important;
            box-shadow: none !important;
          }

          &:after {
            content: "";
            background-color: transparent;
            border: none !important;
            cursor: pointer;
          }
        }
      }

      .product-image-item {
        height: 150px;
        width: 150px;
        border-radius: 15px;
        transition: 0.3s;
        position: relative;
        border: 1px solid rgb(200, 200, 200);

        .btn-clear-img {
          position: absolute;
          top: 2px;
          right: 2px;
          background-color: transparent !important;
          border: none !important;
          outline: none !important;
        }

        .progress {
          position: absolute;
          bottom: 5px;
          left: 50%;
          transform: translate(-50%, 0);
          z-index: 2;
          width: 70%;
          .progress-bar {
            background-color: $primaryColor;
          }
        }
      }
      .product-image-add {
        position: relative;
        height: 150px;
        width: 150px;
        background-color: rgb(239, 239, 239);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border-radius: 15px;
        cursor: pointer;
        transition: 0.3s;

        i {
          color: rgb(120, 120, 120);
          font-size: 30px;
        }

        &:hover {
          box-shadow: 0 8px 8px rgba(0, 0, 0, 0.1);
        }
      }
    }
    .form-group {
      label {
        color: rgb(120, 120, 120);
      }

      .custom-select {
        outline: none !important;
        box-shadow: none !important;
        background-color: rgb(239, 239, 239);
        border: none !important;
      }

      .form-control {
        border: none;
        background-color: rgb(239, 239, 239);
        resize: none;
      }

      .invalid-input {
        color: $errorColor;
        margin-top: 2px;
        i {
          font-size: 18px;
        }
        p {
          padding: 0;
          margin: 0;
        }
      }
    }
  }
}

.parameters {
  background-color: white;
  border-radius: 15px;
  padding: 40px;
  height: 100% !important;

  .parameters-title {
    h5 {
      padding: 0;
      margin: 0;
      color: $primaryColor;
      font-weight: bold;
      font-size: 25px;
    }
  }

  .form-group {
    label {
      color: rgb(120, 120, 120);
    }

    .custom-select {
      outline: none !important;
      box-shadow: none !important;
      background-color: rgb(239, 239, 239);
      border: none !important;
    }

    .form-control {
      border: none;
      background-color: rgb(239, 239, 239);
      resize: none;
    }

    .invalid-input {
      color: $errorColor;
      margin-top: 2px;
      i {
        font-size: 18px;
      }
      p {
        padding: 0;
        margin: 0;
      }
    }
  }
}

.wrapper-detailed-product {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 15;
  overflow-y: scroll;

  .detailed-product {
    min-height: 100vh;
    background-color: white;

    animation-name: show-in-details;
    animation-duration: 0.5s;
    animation-iteration-count: 1;

    @keyframes show-in-details {
      0% {
        transform: translate(0, 100%);
      }
      100% {
        transform: translate(0, 0);
      }
    }

    .detailed-product-header {
      color: $normalGrey;
    }

    .detailed-product-chamber {
      padding: 40px;

      .detailed-product-images {
        min-height: 400px;
      }

      .detailed-product-block {
        p {
          margin: 0;
          padding: 0;
          font-size: 18px;
          color: $normalGrey;
        }
      }

      .detailed-product-description {
        color: $normalGrey;
        margin: 0;
        padding: 0;
      }

      .detailed-product-block-input {
        p {
          margin: 0;
          padding: 0;
          font-size: 18px;
          color: $normalGrey;
        }

        .form-control {
          outline: none !important;
          box-shadow: none !important;
          border: none;
          border-radius: 0 !important;
          border-bottom: 2px solid $lightGrey;
          transition: 0.3s;
          text-align: center;

          &:focus {
            outline: none !important;
            box-shadow: none !important;
            border-bottom: 2px solid $primaryColor;
            border-radius: none !important;
          }

          &:active {
            outline: none !important;
            box-shadow: none !important;
          }
        }
      }
    }

    .detailed-product-description {
      margin: 0;
      padding: 0;
      font-size: 18px;
    }

    .detailed-product-commentary {
      padding: 0;
      margin: 0;

      .media {
        .form-group {
          label {
            color: rgb(120, 120, 120);
          }

          .custom-select {
            outline: none !important;
            box-shadow: none !important;
            background-color: rgb(239, 239, 239);
            border: none !important;
          }

          .form-control {
            border: none;
            background-color: rgb(239, 239, 239);
            resize: none;
          }

          .invalid-input {
            color: $errorColor;
            margin-top: 2px;
            i {
              font-size: 18px;
            }
            p {
              padding: 0;
              margin: 0;
            }
          }
        }
      }
      .detailed-product-commentary-column-left {
        background-color: $lightGrey;
        border-top-right-radius: 40px;
      }
      .detailed-product-commentary-column-right {
        background-color: $lightGrey;
        border-top-left-radius: 40px;
      }
    }
  }
}

.backdrop-products-order {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.2);
  z-index: 30 !important;
  justify-content: center;
  display: flex;
  align-items: center;

  .products-order {
    .header {
      color: $primaryColor;
      font-size: 25px;
      font-weight: bold;
    }
    overflow: auto;
    position: fixed;
    z-index: 35 !important;
    width: 400px;
    height: 90% !important;
    background-color: white;
    border-radius: 15px;

    .btn-close-right {
      cursor: pointer;
      position: absolute;
      top: 5px;
      right: 5px;
      i {
        color: $normalGrey;
      }
    }

    .products-inner {
      position: relative;
      padding: 20px;

      .bs-usd {
        .custom-switch {
        }

        .bs {
          p {
            padding: 0;
            color: $normalGrey;
            font-weight: 500;
            margin: 0;
            margin-right: 10px;
            font-size: 20px;
          }
        }
        .custom-control-input:checked + .custom-control-label::before {
          background-color: $primaryColor;
          border-color: $primaryColor;
        }

        .usd {
          p {
            margin: 0;
            padding: 0;
            color: $normalGrey;
            font-weight: 500;
            font-size: 20px;
          }
        }
      }
    }

    .cart-element {
      padding: 10px;
      position: relative;
      border-radius: 4px;
      color: $normalGrey;
      box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.1);

      .btn-close-right-small {
        position: absolute;
        top: 5px;
        right: 5px;
        cursor: pointer !important;
        z-index: 5;

        i {
          color: $normalGrey;
          font-size: 18px;
        }
      }

      p {
        margin: 0;
        padding: 0;
      }

      background-color: white;
    }
  }
}

.page-footer {
  .color-primary {
    background-color: $primaryColor;
    color: white;
  }

  .dark-color {
    background-color: $darkColor;
    color: white;
  }

  .light-link {
    color: $primaryColor !important;
  }

  .custom-ul {
    list-style-type: square;
    margin: 0;
    padding: 0;
  }

  .footer-copyright {
    background-color: $darkColor;
    color: white;
  }

  .border-primary {
    border: 0.5px solid $primaryColor !important;
    width: 150px;
  }
}

@media screen and (max-width: 700px) {
  .login {
    .login-panel {
      width: 80%;
    }
  }

  .carousel {
    z-index: 1;
    max-height: 200px !important;
    overflow: hidden;

    .carousel-inner {
      height: 200px;
    }

    .carousel-item {
      height: 200px !important;
    }
    img {
      width: 100%;
    }
  }

  .sign-up {
    background-color: $primaryColor;
    height: 100vh;
    justify-content: flex-end;
    align-items: center;
    position: relative;

    .input-group-prepend {
      span {
        border: none !important;
        background-color: $primaryColor;
        color: white;
      }
    }

    .loading-module {
      height: 100vh;
      display: flex;
      justify-content: center;
      align-items: center;

      .loading-header {
        color: white;
        font-size: 20px;

        animation-name: beating;
        animation-duration: 1s;
        animation-iteration-count: infinite;
        animation-timing-function: ease-in-out;
      }

      @keyframes beating {
        0% {
          opacity: 1;
        }
        50% {
          opacity: 0.4;
        }
        100% {
          opacity: 1;
        }
      }
    }

    .account-header {
      .icon-header {
        i {
          color: white;
          font-size: 25px;
        }
      }

      .text-header {
        p {
          color: white;
          padding: 0;
          margin: 0;
          font-size: 25px;
        }
      }
    }

    .sign-up-panel {
      max-width: 90%;
      padding: 40px;
      background-color: white;
      border-top-left-radius: 15px;
      border-top-right-radius: 15px;

      .form-group {
        label {
          color: rgb(120, 120, 120);
        }

        .form-control {
          border: none;
          background-color: rgb(239, 239, 239);
        }

        .invalid-input {
          color: $errorColor;
          margin-top: 2px;
          i {
            font-size: 18px;
          }
          p {
            padding: 0;
            margin: 0;
          }
        }
      }

      .sign-up-link {
        p {
          color: rgb(120, 120, 120);
          a {
            text-decoration: underline;
            cursor: pointer;
            transition: 0.3s;

            &:hover {
              color: $primaryColor;
            }
          }
        }
      }
    }
  }

  .login {
    background-color: $primaryColor;
    height: 100vh;
    justify-content: flex-end;
    align-items: center;
    position: relative;

    .loading-module {
      height: 100vh;
      display: flex;
      justify-content: center;
      align-items: center;

      .loading-header {
        color: white;
        font-size: 20px;

        animation-name: beating;
        animation-duration: 1s;
        animation-iteration-count: infinite;
        animation-timing-function: ease-in-out;
      }

      @keyframes beating {
        0% {
          opacity: 1;
        }
        50% {
          opacity: 0.4;
        }
        100% {
          opacity: 1;
        }
      }
    }

    .account-header {
      .icon-header {
        i {
          color: white;
          font-size: 25px;
        }
      }

      .text-header {
        p {
          color: white;
          padding: 0;
          margin: 0;
          font-size: 25px;
        }
      }
    }

    .login-panel {
      width: 90%;
      padding: 40px;
      background-color: white;
      height: 70%;
      border-top-left-radius: 15px;
      border-top-right-radius: 15px;

      .form-group {
        label {
          color: rgb(120, 120, 120);
        }

        .form-control {
          border: none;
          background-color: rgb(239, 239, 239);
        }
      }

      .sign-up-link {
        p {
          color: rgb(120, 120, 120);
          a {
            text-decoration: underline;
            cursor: pointer;
            transition: 0.3s;

            &:hover {
              color: $primaryColor;
            }
          }
        }
      }
    }
  }

  .admin {
    background-color: $primaryColor;
    height: 100%;
    min-height: 100vh;
    position: relative;

    .admin-panel {
      width: 100% !important;
      margin-left: auto;
      margin-right: auto;
      background-color: white;
      padding: 40px;
      height: 100vh;

      .back-btn-left {
        position: absolute;
        top: 20px;
        left: 20px;
        z-index: 5;

        i {
          color: rgb(40, 40, 40) !important;
          font-size: 30px;
        }
      }

      .loading-module {
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

        .loading-header {
          color: $primaryColor;
          font-size: 20px;

          animation-name: beating;
          animation-duration: 1s;
          animation-iteration-count: infinite;
          animation-timing-function: ease-in-out;
        }

        .lds-default div {
          position: absolute;
          width: 6px;
          height: 6px;
          background: $primaryColor !important;
          border-radius: 50%;
          animation: lds-default 1.2s linear infinite;
        }

        @keyframes beating {
          0% {
            opacity: 1;
          }
          50% {
            opacity: 0.4;
          }
          100% {
            opacity: 1;
          }
        }
      }

      .account-header {
        .icon-header {
          i {
            color: $primaryColor;
            font-size: 30px;
          }
        }

        .text-header {
          p {
            color: $primaryColor;
            padding: 0;
            margin: 0;
            font-size: 25px;
          }
        }
      }

      .form-group {
        label {
          color: rgb(120, 120, 120);
        }

        .form-control {
          border: none;
          background-color: rgb(239, 239, 239);
        }

        .invalid-input {
          color: $errorColor;
          margin-top: 2px;
          i {
            font-size: 18px;
          }
          p {
            padding: 0;
            margin: 0;
          }
        }
      }
    }

    .dashboard {
      padding: 40px;
      position: relative;

      .back-frame {
        cursor: pointer;

        i {
          color: white;
        }

        p {
          padding: 0;
          margin: 0;
          color: white;
        }
      }

      .account-header {
        .icon-header {
          i {
            color: white;
            font-size: 30px;
          }
        }

        .text-header {
          h4 {
            color: white;
            padding: 0;
            margin: 0;
            font-size: 25px;
          }
        }
      }

      .dash-item {
        padding: 40px;
        background-color: white;
        border-radius: 15px;
        cursor: pointer;
        margin: 20px;
        transition: 0.3s;
        width: 200px;

        &:hover {
          box-shadow: 0 8px 8px 0 rgba(0, 0, 0, 0.1);
        }

        .dash-icon {
          i {
            color: $primaryColor;
            font-size: 35px;
          }
        }

        .dash-title {
          h6 {
            padding: 0;
            margin: 0;
            color: $primaryColor;
          }
        }
      }
    }
  }

  .backdrop-products-order {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.2);
    z-index: 30 !important;
    justify-content: center;
    display: flex;
    align-items: center;

    .products-order {
      .header {
        color: $primaryColor;
        font-size: 25px;
        font-weight: bold;
      }
      overflow: auto;
      position: fixed;
      z-index: 35 !important;
      width: 100% !important;
      height: 100% !important;
      background-color: white;
      border-radius: 0 !important;

      .btn-close-right {
        cursor: pointer;
        position: absolute;
        top: 5px;
        right: 5px;
        i {
          color: $normalGrey;
        }
      }

      .products-inner {
        position: relative;
        padding: 20px;

        .bs-usd {
          .custom-switch {
          }

          .bs {
            p {
              padding: 0;
              color: $normalGrey;
              font-weight: 500;
              margin: 0;
              margin-right: 10px;
              font-size: 20px;
            }
          }
          .custom-control-input:checked + .custom-control-label::before {
            background-color: $primaryColor;
            border-color: $primaryColor;
          }

          .usd {
            p {
              margin: 0;
              padding: 0;
              color: $normalGrey;
              font-weight: 500;
              font-size: 20px;
            }
          }
        }
      }

      .cart-element {
        padding: 10px;
        position: relative;
        border-radius: 4px;
        color: $normalGrey;
        box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.1);

        .btn-close-right-small {
          position: absolute;
          top: 5px;
          right: 5px;
          cursor: pointer !important;
          z-index: 5;

          i {
            color: $normalGrey;
            font-size: 18px;
          }
        }

        p {
          margin: 0;
          padding: 0;
        }

        background-color: white;
      }
    }
  }
}

.backdrop {
  position: fixed !important;
  top: 0;
  left: 0;
  z-index: 2;
  width: 100vw;
  height: 100vh;
  position: relative;
  background-color: rgba(0, 0, 0, 0.3);
  animation-name: appear-backdrop;
  animation-duration: 0.5s;
  animation-iteration-count: 1;

  @keyframes appear-backdrop {
    0% {
      background-color: rgba(0, 0, 0, 0);
    }
    100% {
      background-color: rgba(0, 0, 0, 0.3);
    }
  }

  .drawer {
    width: 30%;
    z-index: 3 !important;
    background-color: white;
    height: 100vh;
    position: fixed;
    left: 0;
    top: 0;
    padding-top: 72px;
    padding-bottom: 40px;
    animation-name: show-drawer;
    animation-iteration-count: 1;
    animation-duration: 0.5s;

    @keyframes show-drawer {
      0% {
        transform: translate(-100%, 0);
      }
      100% {
        transform: translate(0, 0);
      }
    }

    > div:nth-child(1) {
      position: relative;
      padding-top: 20px;
      padding-left: 30px;
      padding-bottom: 20px;
      padding-right: 30px;
    }

    .drawer-header {
      color: $primaryColor;
      font-size: 25px;
      font-weight: bold;
    }

    .drawer-categories {
      background-color: $lightGrey;
      height: 100%;
      margin-bottom: 40px;
      padding-top: 20px;
      .drawer-category-item {
        cursor: pointer;
        padding: 30px;

        .drawer-category-icon {
          i {
            color: $secondaryColor;
          }
        }

        .drawer-category-text {
          p {
            padding: 0 !important;
            margin: 0 !important;
            font-size: 20px;
            color: $secondaryColor;
            font-weight: bold;
          }
        }
      }
    }
  }

  .drawer-close {
    animation-name: drawer-close;
    animation-iteration-count: 1;
    animation-duration: 0.5;
  }

  @keyframes drawer-close {
    0% {
      transform: translate(0, 0);
    }
    100% {
      transform: translate(-100%, 0);
    }
  }
}

.backdrop-close {
  animation-name: backdrop-close;
  animation-iteration-count: 1;
  animation-duration: 0.5s;
}

@keyframes backdrop-close {
  0% {
    background-color: rgba(0, 0, 0, 0.3);
  }
  100% {
    background-color: rgba(0, 0, 0, 0);
  }
}

//SIDE NAV SECTION

.side-nav {
  position: fixed;
  top: 0;
  left: 0;
  width: 350px;
  height: 100vh;
  background-color: white;
  z-index: 2;
  box-shadow: 0 8px 8px 0 rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;

  .side-nav-header {
    cursor: pointer;
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 30px;
    padding-bottom: 30px;
    i {
      font-size: 40px;
      color: $primaryColor;
    }

    h4 {
      padding: 0;
      margin: 0;
      color: $primaryColor;
    }
  }

  .side-nav-body {
    padding: 0;
    margin: 0;
    list-style: none;
    background-color: $softPrimaryColor;

    li {
      a {
        padding: 15px;
        display: block;
        color: $primaryColor;
        transition: 0.2s;

        span:nth-child(1) {
          i {
            font-size: 30px;
          }
        }

        span:nth-child(2) {
          font-size: 20px;
          padding: 0;
          margin: 0;
        }

        &:hover {
          background-color: darken($color: $softPrimaryColor, $amount: 4);
        }
      }
    }
  }

  .side-nav-footer {
    list-style: none;
    padding: 0;
    margin: 0;
    li {
      a {
        padding: 15px;
        display: block;

        span:nth-child(1) {
          i {
            font-size: 40px;
            color: $primaryColor;
          }
        }
      }
    }
  }
}

.backdrop-cart {
  position: fixed !important;
  top: 0;
  left: 0;
  z-index: 15;
  width: 100vw;
  height: 100vh;
  position: relative;
  background-color: rgba(0, 0, 0, 0.3);
  animation-name: appear-backdrop;
  animation-duration: 0.5s;
  animation-iteration-count: 1;

  @keyframes appear-backdrop {
    0% {
      background-color: rgba(0, 0, 0, 0);
    }
    100% {
      background-color: rgba(0, 0, 0, 0.3);
    }
  }

  .drawer-cart {
    overflow-y: auto;

    .info-order {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 20px;
      height: 100%;

      h4 {
        text-align: center;
      }
    }

    .transfer-proof {
      padding: 20px;

      .total-module {
        h5 {
          color: $primaryColor;
          font-weight: bold;
        }
      }

      .account-details-header {
        color: $normalGrey;
        font-weight: bold;
      }

      .account-details {
        padding: 10px;
        border-radius: 4px;
        border: 1px solid $lightNormalGrey;

        p {
          color: $normalGrey;
          margin: 0;
          padding: 0;
        }
        h5 {
          color: $normalGrey;
          padding: 0;
          margin: 0;
          font-weight: normal;
        }
      }

      .custom-file {
        outline: none !important;
        box-shadow: none !important;

        input {
          outline: none !important;
          box-shadow: none !important;
        }
        .custom-file-label {
          outline: none !important;
          box-shadow: none !important;
          border-color: $lightNormalGrey;
          &::after {
            content: "Buscar";
          }
        }
      }
    }

    .request-form {
      padding: 20px;

      .direction-display {
        padding: 10px;
        border-radius: 4px;
        border: 1px solid $lightNormalGrey;

        h5 {
          color: $normalGrey;
          padding: 0;
          margin: 0;
        }

        p {
          padding: 0;
          margin: 0;
          color: $normalGrey;
        }
      }
      .form-group {
        label {
          color: rgb(120, 120, 120);
        }

        .form-control {
          border: none;
          background-color: rgb(239, 239, 239);
        }

        .custom-select {
          outline: none !important;
          box-shadow: none !important;
          background-color: rgb(239, 239, 239);
          border: none !important;
        }
      }
    }

    label {
      color: rgb(120, 120, 120);
    }

    .bs-usd {
      .custom-switch {
      }

      .bs {
        p {
          padding: 0;
          color: $normalGrey;
          font-weight: 500;
          margin: 0;
          margin-right: 10px;
          font-size: 20px;
        }
      }
      .custom-control-input:checked + .custom-control-label::before {
        background-color: $primaryColor;
        border-color: $primaryColor;
      }

      .usd {
        p {
          margin: 0;
          padding: 0;
          color: $normalGrey;
          font-weight: 500;
          font-size: 20px;
        }
      }
    }

    width: 30%;
    z-index: 16 !important;
    background-color: white;
    height: 100vh;
    position: fixed;
    right: 0;
    top: 0;
    padding-bottom: 40px;
    animation-name: show-drawer-cart;
    animation-iteration-count: 1;
    animation-duration: 0.5s;

    @keyframes show-drawer-cart {
      0% {
        transform: translate(100%, 0);
      }
      100% {
        transform: translate(0, 0);
      }
    }

    > div:nth-child(1) {
      position: relative;
      padding-top: 20px;
      padding-left: 30px;
      padding-bottom: 20px;
      padding-right: 30px;
    }

    .cart-top {
      padding: 20px;
    }

    .drawer-header {
      color: $primaryColor;
      font-weight: bold;
      font-size: 25px;
    }

    .drawer-elements {
      .total-module {
        h5 {
          color: $secondaryColor;
          font-weight: bold;
        }
      }

      .custom-select {
        outline: none !important;
        box-shadow: none !important;
        background-color: rgb(239, 239, 239);
        border: none !important;
      }

      .form-control {
        border: none;
        border-bottom: 2px solid rgb(239, 239, 239);
        resize: none;
        text-align: center;
        padding: 0 !important;
        height: 20px;
        font-size: 16px;
        border-radius: 0;

        &:focus {
          border-bottom: 2px solid $primaryColor;
        }
      }

      .invalid-input {
        color: $errorColor;
        margin-top: 2px;
        i {
          font-size: 18px;
        }
        p {
          padding: 0;
          margin: 0;
        }
      }

      background-color: $lightGrey;
      margin-bottom: 40px;
      padding-top: 20px;
      padding-left: 20px;
      padding-bottom: 20px;
      padding-right: 20px;

      .cart-element {
        padding: 10px;
        position: relative;
        border-radius: 4px;
        color: $normalGrey;
        box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.1);

        .btn-close-right-small {
          position: absolute;
          top: 5px;
          right: 5px;
          cursor: pointer !important;
          z-index: 5;

          i {
            color: $normalGrey;
            font-size: 18px;
          }
        }

        p {
          margin: 0;
          padding: 0;
        }

        background-color: white;
      }
      .drawer-category-item {
        cursor: pointer;
        padding: 30px;

        .drawer-category-icon {
          i {
            color: $primaryDark;
          }
        }

        .drawer-category-text {
          p {
            padding: 0 !important;
            margin: 0 !important;
            font-size: 20px;
            color: $primaryDark;
            font-weight: bold;
          }
        }
      }
    }
  }

  .drawer-close-cart {
    animation-name: drawer-close-cart;
    animation-iteration-count: 1;
    animation-duration: 0.5;
  }

  @keyframes drawer-close-cart {
    0% {
      transform: translate(0, 0);
    }
    100% {
      transform: translate(100%, 0);
    }
  }
}

@media screen and (max-width: 700px) {
  .sub-nav {
    .btn-transparent {
      span {
        display: none;
      }
    }

    .bs-usd {
      margin-right: 0 !important;
      padding: 5px;

      .custom-switch {
      }

      .bs {
        p {
          padding: 0;
          color: white;
          font-weight: 500;
          margin: 0;
          margin-right: 5px;
          font-size: 14px;
        }
      }
      .custom-control-input:checked + .custom-control-label::before {
        background-color: $primaryColor;
        border-color: $primaryColor;
      }

      .usd {
        p {
          margin: 0;
          padding: 0;
          color: white;
          font-weight: 500;
          font-size: 14px;
        }
      }
    }

    padding: 5px;
    background-color: $secondaryColor;
    justify-content: space-between;
    align-items: center;
    display: flex;

    .input-group {
      width: 200px !important;

      .input-group-text {
        background-color: white;
        border: none;

        i {
          color: rgb(150, 150, 150);
        }
      }
      .form-control {
        outline: none;
        border: none;
      }
    }
  }

  .drawer-cart {
    overflow-y: auto;

    .info-order {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 20px;
      height: 100%;

      h4 {
        text-align: center;
      }
    }

    .transfer-proof {
      padding: 20px;

      .total-module {
        h5 {
          color: $primaryColor;
          font-weight: bold;
        }
      }

      .account-details-header {
        color: $normalGrey;
        font-weight: bold;
      }

      .account-details {
        padding: 10px;
        border-radius: 4px;
        border: 1px solid $lightNormalGrey;

        p {
          color: $normalGrey;
          margin: 0;
          padding: 0;
        }
        h5 {
          color: $normalGrey;
          padding: 0;
          margin: 0;
          font-weight: normal;
        }
      }

      .custom-file {
        outline: none !important;
        box-shadow: none !important;

        input {
          outline: none !important;
          box-shadow: none !important;
        }
        .custom-file-label {
          outline: none !important;
          box-shadow: none !important;
          border-color: $lightNormalGrey;
          &::after {
            content: "Buscar";
          }
        }
      }
    }

    .request-form {
      padding: 20px;

      .direction-display {
        padding: 10px;
        border-radius: 4px;
        border: 1px solid $lightNormalGrey;

        h5 {
          color: $normalGrey;
          padding: 0;
          margin: 0;
        }

        p {
          padding: 0;
          margin: 0;
          color: $normalGrey;
        }
      }
      .form-group {
        label {
          color: rgb(120, 120, 120);
        }

        .form-control {
          border: none;
          background-color: rgb(239, 239, 239);
        }

        .custom-select {
          outline: none !important;
          box-shadow: none !important;
          background-color: rgb(239, 239, 239);
          border: none !important;
        }
      }
    }

    label {
      color: rgb(120, 120, 120);
    }

    .bs-usd {
      .custom-switch {
      }

      .bs {
        p {
          padding: 0;
          color: $normalGrey;
          font-weight: 500;
          margin: 0;
          margin-right: 10px;
          font-size: 20px;
        }
      }
      .custom-control-input:checked + .custom-control-label::before {
        background-color: $primaryColor;
        border-color: $primaryColor;
      }

      .usd {
        p {
          margin: 0;
          padding: 0;
          color: $normalGrey;
          font-weight: 500;
          font-size: 20px;
        }
      }
    }

    width: 100% !important;
    z-index: 16 !important;
    background-color: white;
    height: 100vh;
    position: fixed;
    right: 0;
    top: 0;
    padding-bottom: 40px;
    animation-name: show-drawer-cart;
    animation-iteration-count: 1;
    animation-duration: 0.5s;

    @keyframes show-drawer-cart {
      0% {
        transform: translate(100%, 0);
      }
      100% {
        transform: translate(0, 0);
      }
    }

    > div:nth-child(1) {
      position: relative;
      padding-top: 20px;
      padding-left: 30px;
      padding-bottom: 20px;
      padding-right: 30px;
    }

    .cart-top {
      padding: 20px;
    }

    .drawer-header {
      color: $primaryColor;
      font-weight: bold;
      font-size: 25px;
    }

    .drawer-elements {
      .total-module {
        h5 {
          color: $secondaryColor;
          font-weight: bold;
        }
      }

      .custom-select {
        outline: none !important;
        box-shadow: none !important;
        background-color: rgb(239, 239, 239);
        border: none !important;
      }

      .form-control {
        border: none;
        border-bottom: 2px solid rgb(239, 239, 239);
        resize: none;
        text-align: center;
        padding: 0 !important;
        height: 20px;
        font-size: 16px;
        border-radius: 0;

        &:focus {
          border-bottom: 2px solid $primaryColor;
        }
      }

      .invalid-input {
        color: $errorColor;
        margin-top: 2px;
        i {
          font-size: 18px;
        }
        p {
          padding: 0;
          margin: 0;
        }
      }

      background-color: $lightGrey;
      margin-bottom: 40px;
      padding-top: 20px;
      padding-left: 20px;
      padding-bottom: 20px;
      padding-right: 20px;

      .cart-element {
        padding: 10px;
        position: relative;
        border-radius: 4px;
        color: $normalGrey;
        box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.1);

        .btn-close-right-small {
          position: absolute;
          top: 5px;
          right: 5px;
          cursor: pointer !important;
          z-index: 5;

          i {
            color: $normalGrey;
            font-size: 18px;
          }
        }

        p {
          margin: 0;
          padding: 0;
        }

        background-color: white;
      }
      .drawer-category-item {
        cursor: pointer;
        padding: 30px;

        .drawer-category-icon {
          i {
            color: $primaryDark;
          }
        }

        .drawer-category-text {
          p {
            padding: 0 !important;
            margin: 0 !important;
            font-size: 20px;
            color: $primaryDark;
            font-weight: bold;
          }
        }
      }
    }
  }

  .page-footer {
    .row {
      margin: 0 !important;
      padding: 0 !important;
    }
    .custom-ul {
      padding-bottom: 10px !important;
    }

    .color-primary {
      padding: 20px;
    }
  }

  .drawer {
    width: 100% !important;
    z-index: 3 !important;
    background-color: white;
    height: 100vh;
    position: fixed;
    left: 0;
    top: 0;
    padding-top: 72px;
    padding-bottom: 40px;
    animation-name: show-drawer;
    animation-iteration-count: 1;
    animation-duration: 0.5s;

    @keyframes show-drawer {
      0% {
        transform: translate(-100%, 0);
      }
      100% {
        transform: translate(0, 0);
      }
    }

    > div:nth-child(1) {
      position: relative;
      padding-top: 20px;
      padding-left: 30px;
      padding-bottom: 20px;
      padding-right: 30px;
    }

    .drawer-header {
      color: $primaryColor;
      font-size: 25px;
      font-weight: bold;
    }

    .drawer-categories {
      background-color: $lightGrey;
      height: 100%;
      margin-bottom: 40px;
      padding-top: 20px;
      .drawer-category-item {
        cursor: pointer;
        padding: 30px;

        .drawer-category-icon {
          i {
            color: $secondaryColor;
          }
        }

        .drawer-category-text {
          p {
            padding: 0 !important;
            margin: 0 !important;
            font-size: 20px;
            color: $secondaryColor;
            font-weight: bold;
          }
        }
      }
    }
  }
}
